import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import Flickity from 'react-flickity-component'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import Copyright from '../components/copyright'

const flickityHero = {
    prevNextButtons: true,
    pageDots: false,
    cellSelector: '.slider__hero-slider-cell',
    wrapAround: true,
    setGallerySize: true,
    cellAlign: 'center',
    autoPlay: 5000,
}

function SliderHero(p) {
    return (
        <Flickity
        className={'slider__hero'}
        elementType={'div'}
        options={flickityHero}
        disableImagesLoaded={false}
        reloadOnUpdate={true}
        static={true}
        >
        {p.p.map((slide, i) => {
            return (
            <div key={i} className="slider__hero-slider-cell">
                <BackgroundImage style={{ height: "400px" }} fluid={slide.image.childImageSharp.fluid}>
                    <div className="hero__gradient hero__relative">
                        <div className="hero__text center">
                            <h2>{slide.text}</h2>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            )
        })}
        </Flickity>
    )
}

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <SliderHero p={entry.frontmatter.slides} />
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2-1 grid__gap--48 text">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <a href="https://bookings.gettimely.com/andrewwaldegraveltd/book?uri=https%3A%2F%2Fbook.gettimely.com%2FBooking%2FLocation%2F153135%3Fmobile%3DTrue%26params%3D%25253fclient-login%25253dtrue" rel="nofollow noopener" title="Contact" className="btn btn__large btn__margin">Book Online &nbsp;►</a>
                            </div>
                            <div className="aside">
                                <Img fluid={entry.frontmatter.side_image.childImageSharp.fluid} alt={entry.frontmatter.side_heading} />
                                <h3>{entry.frontmatter.side_heading}</h3>
                                <ul>
                                    {entry.frontmatter.side_text.map( (entry, i) => (
                                        <li key={i}>{entry.text}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                slides {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1280) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    text
                }
                side_heading
                side_text {
                    text
                }
                side_image {
                    childImageSharp {
                        fluid(maxWidth: 640, maxHeight: 480) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`